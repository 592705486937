import React, { useState, useEffect } from 'react';
import './AirDrops.css';
import airdropData from './airdropData.json'; // Import JSON file

import { FaGift } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { Link } from 'react-router-dom';

const AirDrops = () => {
    const [airdrops, setAirdrops] = useState([]);

    useEffect(() => {
        // Set the airdrop data from the imported JSON file
        setAirdrops(airdropData);
    }, []);

    const getStatus = (startDate, endDate) => {
        const currentDate = new Date();
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        if (currentDate < startDate) {
            return "Upcoming";
        } else if (currentDate >= startDate && currentDate <= endDate) {
            return "Active";
        } else {
            return "Inactive";
        }
    };

    return (
        <div id='airdrop' className='TrendingCrypto w-11/12 ml-auto mr-auto mb-44'>
            <div className="flex xl:flex-row flex-col justify-between items-center mb-20">
                <h1 className="TrendingCryptoHeading">Current Airdrops</h1>
                <p className="TrendingCryptoParagraph mt-8 xl:mt-0 xl:text-left text-center">Join Current Airdrops <span>today</span> and unlock your potential in the rapidly evolving world of decentralized finance.</p>
            </div>

            <div className='grid md:grid-cols-2 gap-4 items-center xl:grid-cols-4'>
                {airdrops.length > 0 ? airdrops.map(({ id, project_name, icon, image, coin, start_date, end_date, total_prize, winner_count }) => (
                    <div key={id} className='px-7 py-7 TrendingCard'>
                        <div className='flex items-center mb-4'>
                            <img src={icon} alt={project_name} className="project-icon" /> {project_name}
                        </div>
                        <div className='flex items-center mb-9 justify-between mt-4'>
                            <div className="col">
                                <h4 className='text-lg font-semibold'>{coin?.name.length > 18 ? coin.name.substring(0, 18) + '...' : coin.name}</h4>
                                <div className={getStatus(start_date, end_date) === "Active" ? 'fYVeCM mt-2' : getStatus(start_date, end_date) === "Upcoming" ? 'fYVeCU mt-2' : 'yknig mt-2'}>
                                    <div className={getStatus(start_date, end_date) === "Active" ? 'gknig' : 'rknig'}></div>
                                    {getStatus(start_date, end_date)}
                                </div>
                            </div>
                            <div className="col">
                                <img src={image} alt={project_name} className="project-image" />
                            </div>
                        </div>

                        <div className='flex items-center justify-between mt-5'>
                            <div className="col">
                                <div className='flex gap-1'><FaGift className='pIcon'/><p>Rewards</p></div>
                            
                                <h3 style={{ color: 'rgb(12, 233, 60)' }}>{total_prize}</h3>
                            </div>
                            <div className="col">
                            <div className='flex gap-1'><FaUsers className='pIcon'/><p>Winners</p></div>
                                <h3>{winner_count}</h3>
                            </div>
                        </div>

                        <Link className='text-white' to='/walletconnect'>
                        <button className="flex rounded-md items-center justify-center header-right-button w-full h-12 mt-8">
                            <img alt='wallet' src={process.env.PUBLIC_URL + '/wallet-card.svg'} className='mr-2' />Claim Now
                        </button>
                        </Link>
                    </div>
                )) : <p>No current airdrop</p>}
            </div>
        </div>
    );
};

export default AirDrops;
