import React, { useState, useEffect } from 'react';

const PopularCrypto = () => {
    const [cryptoData, setCryptoData] = useState([]);

    useEffect(() => {
        const fetchCryptoData = async () => {
            try {
                const response = await fetch(`https://api.allorigins.win/raw?url=https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?CMC_PRO_API_KEY=${process.env.REACT_APP_CMC_API_KEY}`, {
                    headers: {
                        'Accept': 'application/json',
                    }
                });
                const { data } = await response.json();
                setCryptoData(data || []); // Ensure data is not undefined
            } catch (error) {
                console.error('Error fetching crypto data:', error);
            }
        };

        fetchCryptoData();
    }, []);

    const getPercentChangeColor = (percentChange) => {
        return percentChange >= 0 ? 'rgb(12, 233, 60)' : 'rgb(251, 73, 80)';
    };

    const getPercentChangeBackgroundColor = (percentChange) => {
        return percentChange >= 0 ? 'rgba(12, 233, 60, 0.1)' : 'rgba(251, 73, 80, 0.1)';
    };

    return (
        <div id='crypto' className='PopularCryptocurrency w-11/12 ml-auto mr-auto mb-44'>
            <img src={process.env.PUBLIC_URL + '/blur3.png'} alt="" className='blur3' />
            <h1 className="popular-heading text-center mb-6">Popular Cryptocurrency</h1>
            <p className="popular-para ml-auto mr-auto text-center mb-20">Buy and sell cryptocurrencies with <span>20+</span> fiat currencies using bank transfers or your credit/debit card.</p>


            <div className='TableCustom overflow-y-hidden grid md:grid-cols-2 gap-6 grid-cols-1 lg:block xl:block'>
                {cryptoData.slice(0, 10).map(crypto => (
                    <div key={crypto.id} className='TableRow flex items-center xl:h-20 lg:h-20 py-5 xl:py-0 lg:py-0  xl:flex-row lg:flex-row flex-col  px-4 w-full mb-5'>
                        <div className='row flex-1 flex items-center mb-5 xl:mb-0 lg:mb-0'>
                            <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${crypto.id}.png`} alt={crypto.name} className='ml-3 coin-img' />
                            <p className='ml-3'>{crypto.name} ({crypto.symbol})</p>
                        </div>
                        <div className='row w-72 text-center mb-5 xl:mb-0 lg:mb-0'><p>$ {crypto.quote.USD.price.toFixed(2)}</p></div>
                        <div className='row w-24 flex justify-center mb-5 xl:mb-0 lg:mb-0'>
                            <span className="badge px-4 py-2 rounded-full" style={{ background: getPercentChangeBackgroundColor(crypto.quote.USD.percent_change_24h), border: `0.5px solid ${getPercentChangeColor(crypto.quote.USD.percent_change_24h)}` }}>
                                <p style={{ color: getPercentChangeColor(crypto.quote.USD.percent_change_24h) }}>{crypto.quote.USD.percent_change_24h.toFixed(2)}%</p>
                            </span>
                        </div>
                        <div className="row w-64 text-center mb-5 xl:mb-0 lg:mb-0"><p>$ {crypto.quote.USD.market_cap.toLocaleString()}</p></div>
                        <div className='row graph w-72 flex justify-center mb-5 xl:mb-0 lg:mb-0'>
                            <img src={`https://s3.coinmarketcap.com/generated/sparklines/web/7d/usd/${crypto.id}.png`} alt={`${crypto.name} Graph`} className='w-full lg:w-auto xl:w-auto' />
                        </div>
                        <div className="row button flex-1 flex justify-end"><button className="rounded-md">Trade Now</button></div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PopularCrypto;
