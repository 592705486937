import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {
    return (
        <div>
            <div className='Main mt-32 grid xl:grid-cols-2 grid-cols-1 xl:text-left text-center w-11/12 ml-auto mr-auto items-center'>
                <img src={process.env.PUBLIC_URL + '/blur.png'} alt='blur' className='blur1' />

                <div className='left-area order-2 lg:order-1 xl:order-1'>
                    <h1 className="heading-main">Unlock your rewards <span className="">with Airdrop</span> <span>Claims!</span></h1>

                    <span className="span-main-first">Claim your free tokens and cryptocurrencies now.</span>

                    <p className="paragraph-main">Don't miss out on the opportunity to grab your share of airdrop rewards. <br /> Get started today and claim your tokens hassle-free.</p>

                    <div className='buttons-wrapper flex items-center xl:justify-stretch justify-center'>
                        <Link className='text-white' to='/walletconnect'>
                            <button className="flex items-center main-button-green w-48 h-16 justify-center rounded-lg">
                                Get Started<img src={process.env.PUBLIC_URL + '/arrow-right.svg'} alt="" className="ml-2" />
                            </button>
                        </Link>

                        <a href='#airdrop'>
                            <button className="flex items-center main-button-transparent w-48 h-16 justify-center rounded-lg ml-4 ">
                                All Airdrops<img src={process.env.PUBLIC_URL + '/arrow-green.svg'} alt="" className="ml-2" />
                            </button>
                        </a>
                    </div>
                </div>
                <div className="right-area xl:block flex justify-center order-1 lg:order-2 xl:order-2">
                    <img src={process.env.PUBLIC_URL + '/btc.svg'} alt="coin" className="w-100" />
                </div>
            </div>

            <div className="BinanceRow mt-28 mb-36 ">
                <div className="inner-row w-11/12 grid xl:flex md:grid-cols-2  items-center justify-between xl:h-32 xl:py-0 py-5 gap-10 xl:gap-0  ml-auto mr-auto">
                    <img src={process.env.PUBLIC_URL + '/Binance.svg'} alt="Binance" />
                    <img src={process.env.PUBLIC_URL + '/CryptoCom.svg'} alt="CryptoCom" />
                    <img src={process.env.PUBLIC_URL + '/MetaMask.svg'} alt="MetaMask" />
                    <img src={process.env.PUBLIC_URL + '/OpenSea.svg'} alt="OpenSea" />
                    <img src={process.env.PUBLIC_URL + '/Seedify.svg'} alt="Seedify" />
                    <img src={process.env.PUBLIC_URL + '/Compound.svg'} alt="Compound" />
                </div>
            </div>
        </div>
    )
}

export default Hero