import React from 'react'

const Steps = () => {
    return (
        <div className="PopularCryptocurrency w-11/12 ml-auto mr-auto mb-24">
            <h1 className="popular-heading text-center mb-6">How Airdrop Claim Works</h1>
            <p className="popular-para ml-auto mr-auto text-center mb-20">Airdrop Claim allows users to participate in airdrops, a distribution of <span>free</span> tokens or cryptocurrencies.</p>
            <div className="grid  xl:grid-cols-4 gap-5 AppSteps md:grid-cols-2">
                <div className="AppCard">
                    <img src={process.env.PUBLIC_URL + '/step1.svg'} alt="" />
                    <h1 className="mt-12">Vist Website</h1>
                    <p className="mt-5">Go to our Website from your Mobile Device and others platforms;</p>
                </div>
                <div className="AppCard">
                    <img src={process.env.PUBLIC_URL + '/step2.svg'} alt="" />
                    <h1 className="mt-12">Connect Wallet</h1>
                    <p className="mt-5">Connect your crypto wallet to our Website</p>
                </div>
                <div className="AppCard">
                    <img src={process.env.PUBLIC_URL + '/step3.svg'} alt="" />
                    <h1 className="mt-12">Start Claiming</h1>
                    <p className="mt-5">Easily start your airdrop claim on our website</p>
                </div>
                <div className="AppCard">
                    <img src={process.env.PUBLIC_URL + '/step4.svg'} alt="" />
                    <h1 className="mt-12">Earn Money</h1>
                    <p className="mt-5">Make your passive income by being up to date</p>
                </div>
            </div>
        </div>
    )
}

export default Steps