import React from 'react'
import WalletMain from './WalletMain'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

const WalletConnect = () => {
  return (
    <>
      <Header />
      <div className='pt-10 px-2 md:px-20'>
      <WalletMain />
      </div>
      <Footer />
    </>
  )
}

export default WalletConnect