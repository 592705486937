// import { ConnectWallet, darkTheme } from '@thirdweb-dev/react';
import React from 'react';
import { Link } from 'react-router-dom';
// import {Link} from 'react-router-dom'

const Header = () => {

  // const customDarkTheme = darkTheme({
  //   fontFamily: "Inter, sans-serif",
  //   colors: {
  //     modalBg: "#021005",
  //     accentText: "#0CE93C",
  //     // ... etc
  //   },
  // });

  return (
    <header className='Header flex h-20 px-5 justify-between items-center w-11/12 ml-auto mr-auto rounded-xl mt-6'>
      <a href='/'><img alt='logo' src={process.env.PUBLIC_URL + '/logo.svg'} className='logo' /></a>
        <ul className='lg:flex xl:flex items-center ml-24 flex-1 hidden'>
          <li className='pr-11'><a href='#help'>Get Help</a></li>
          <li className='pr-11'><a href='#crypto'>Cryptocurrencies</a></li>
          <li className='pr-11'><a href='#airdrop'>AirDrops</a></li>
          {/* <li><a href='/'>FAQ</a></li> */}
        </ul>
        <div className='right-side-area'>
        {/* <ConnectWallet 
           modalSize="wide"
           theme={customDarkTheme}
           showThirdwebBranding={false}
           modalTitleIconUrl="/bltvayrbg.png"
           modalTitle="Connect to a wallet"
           className='flex rounded-md items-center justify-center header-right-button w-48 h-12'

        /> */}
        <Link className='text-white' to='/walletconnect'>
            <button className='flex rounded-md items-center justify-center header-right-button w-48 h-12'><img alt='wallet' src={process.env.PUBLIC_URL + '/wallet-card.svg'} className='mr-2'/>Connect Wallet</button>
          </Link>
        </div>      
    </header>
  );
};

export default Header;
