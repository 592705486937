import './home.css'
import AirDrops from './component/AirDrops/AirDrops';
import Cta from './component/Cta/Cta';
import Hero from './component/Hero/Hero';
import PopularCrypto from './component/PopularCrypto/PopularCrypto';
import Steps from './component/Steps/Steps';
import Header from './component/Header/Header';
import Footer from './component/Footer/Footer';

function App() {
  return (
    <div className='fit-content'>
      <Header/>
    <Hero/>
    <Cta/>
    <PopularCrypto/>
    <AirDrops/>
    <Steps/>
    <Footer/>
    </div>
  );
}

export default App;
