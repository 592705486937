import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import App from './App';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import WalletConnect from './component/WalletConnect/WalletConnect';
import NotFound from './component/NotFound/NotFound';

import {
  ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  trustWallet,
  zerionWallet,
  phantomWallet,
  rainbowWallet,
} from "@thirdweb-dev/react";

const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <React.StrictMode>
    <ThirdwebProvider
      supportedWallets={[
        metamaskWallet({
          recommended: true,
        }),
        coinbaseWallet(),
        walletConnect(),
        trustWallet(),
        zerionWallet(),
        phantomWallet(),
        rainbowWallet()
      ]}
      clientId="c9be6ec00130e557d8a877b4cd3523f8"
    >
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/walletconnect" element={<WalletConnect />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
      </Routes>
    </BrowserRouter>
    </ThirdwebProvider>
  </React.StrictMode>
);
