import React from 'react'

const Footer = () => {
    return (
        <div className="Footer">
            <img src={process.env.PUBLIC_URL + '/last-blur.svg'} alt="" className="blurLast" />
            <div className="flex xl:flex-row flex-col items-center justify-between w-11/12 ml-auto mr-auto xl:h-32 py-5 xl:py-0">
                <div className="flex items-center xl:w-auto w-full justify-between xl:justify-stretch">
                    <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="" />
                    <p className="start ml-16">Get started with the easiest and most secure platform to get airdrops, <br/>trade, and earn cryptocurrencies.</p>
                </div>
                <p className="end mt-5 xl:mt-0">© Copyright 2024 <span>Bitvay</span> I All Rights Reserved</p>
            </div>
        </div>
    )
}

export default Footer