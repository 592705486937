// NotFound.js

import React from 'react';
import './NotFound.css'; // Import the CSS file for styling
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const NotFound = () => {
  return (
    <>
    <Header/>
    <div className="not-found-container">
      <h1 className="not-found-header">404 - Page Not Found</h1>
      <p className="not-found-text">Oops! Looks like you've wandered into uncharted territory.</p>
      <img src="/not-found.gif" alt="Crypto Unicorn" className="not-found-image" />
    </div>
    <Footer/>
    </>
  );
};

export default NotFound;
